import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const FailurePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id, message } = location.state || {};

    return (
        <div className="failure-container">
            <div className="failure-icon">❌</div>
            <h1>Payment Failed</h1>
            {message ? <p>{message}</p> : <p>Unfortunately, your payment could not be processed.</p>}
            {id && !message?.includes('Retry is not allowed') && (
                <button className="retry-button" onClick={() => navigate(`/checkout/${id}`)}>
                    Retry
                </button>
            )}
            <button className="cancel-button" onClick={() => navigate('/')}>
                Cancel
            </button>
            <p className="powered-by">Powered by ZirooPay</p>
        </div>
    );
};

export default FailurePage;
