/**
 * Stores a value in localStorage.
 * Converts the value to a JSON string before storing.
 *
 * @param key - The key to store the value under.
 * @param value - The value to store, which can be any type.
 */
export const setToLocalStorage = <T>(key: string, value: T): void => {
    if (value !== null && value !== undefined) {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

/**
 * Retrieves a value from localStorage.
 * Parses the JSON string back into its original type.
 *
 * @param key - The key under which the value is stored.
 * @returns The parsed value from localStorage, or null if it does not exist.
 */
export const getFromLocalStorage = (key: string): string => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue).toString() : null;
};
