import React from 'react';
import './ReceiptPage.css';
import Receipt from "../components/Receipt";
import {getFromLocalStorage} from "../localStorageUtils";

const ReceiptPage: React.FC = () => {
    const paymentMethod = getFromLocalStorage('paymentMethod') || 'card';
    const price = getFromLocalStorage('price') || '50000';
    const currency = getFromLocalStorage('currency') || '$';
    const date = getFromLocalStorage('date') ? new Date(getFromLocalStorage('date')) : new Date();
    const receiptNumber = getFromLocalStorage('receiptNumber') || '1';
    const cardNumber = getFromLocalStorage('cardNumber') || "4999 0821 0002 9373";
    const expiry = getFromLocalStorage('expiry') || "12/24";
    const accountNumber = paymentMethod === 'transfer' ? getFromLocalStorage('accountNumber') : undefined;
    const bankName = paymentMethod === 'transfer' ? getFromLocalStorage('bankName') : undefined;
    const phoneNumber = paymentMethod === 'phone' ? getFromLocalStorage('phoneNumber') : undefined;
    const beneficiaryAccountNumber = paymentMethod === 'transfer' ? getFromLocalStorage('beneficiaryAccountNumber') : undefined;
    const beneficiaryAccountName = paymentMethod === 'transfer' ? getFromLocalStorage('beneficiaryAccountName') : undefined;
    const originatingAccountNumber = paymentMethod === 'transfer' ? getFromLocalStorage('originatingAccountNumber') : undefined;
    const originatingAccountName = paymentMethod === 'transfer' ? getFromLocalStorage('originatingAccountName') : undefined;
    const originatingBankName = paymentMethod === 'transfer' ? getFromLocalStorage('originatingBankName') : undefined;
    const transactionDate = paymentMethod === 'transfer' ? getFromLocalStorage('transactionDate') : undefined;
    const status = paymentMethod === 'transfer' ? getFromLocalStorage('status') : undefined;
    const referenceID = paymentMethod === 'transfer' ? getFromLocalStorage('referenceID') : undefined;

    return (
        <div className="receipt-page-container">
            <Receipt
                open={true}
                onClose={() => window.close()}
                paymentMethod={paymentMethod}
                price={price}
                currency={currency}
                date={date}
                receiptNumber={receiptNumber}
                cardNumber={cardNumber}
                accountNumber={accountNumber}
                expiry={expiry}
                bankName={bankName}
                phoneNumber={phoneNumber}
                beneficiaryAccountNumber={beneficiaryAccountNumber}
                beneficiaryAccountName={beneficiaryAccountName}
                originatingAccountNumber={originatingAccountNumber}
                originatingAccountName={originatingAccountName}
                originatingBankName={originatingBankName}
                transactionDate={transactionDate}
                status={status}
                referenceID={referenceID}
            />
        </div>
    );
};

export default ReceiptPage;
