import React, {useEffect, useState} from 'react';

interface CardDetailsFormProps {
    onSubmit: (cardDetails: { cardNumber: string; expiry: string; cvc: string }) => void;
}

const CardDetailsForm: React.FC<CardDetailsFormProps> = ({ onSubmit }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const validateForm = () => {
        const isValidCardNumber = /^[0-9]{16}$/.test(cardNumber.replace(/\s+/g, ''));
        const isValidExpiry = /^[0-9]{2}\/[0-9]{2}$/.test(expiry);
        const isValidCVC = /^[0-9]{3}$/.test(cvc);

        setIsFormValid(isValidCardNumber && isValidExpiry && isValidCVC);
    };

    // Trigger validation whenever an input changes
    useEffect(() => {
        validateForm();
    }, [cardNumber, expiry, cvc]);

    const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '').replace(/(\d{4})/g, '$1 ').trim();
        setCardNumber(value);
    };

    const handleExpiryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 2) {
            value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
        }
        setExpiry(value);
    };

    const handleCVCChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 3);
        setCvc(value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit({ cardNumber, expiry, cvc });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <input
                    type="text"
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                    required
                    placeholder="Card Number"
                    className="card-details-wide"
                    maxLength={19} // 16 digits + 3 spaces
                />
            </div>
            <div>
                <input
                    type="text"
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value.replace(/[^a-zA-Z\s]/g, ''))}
                    placeholder="Cardholder Name"
                    className="card-details-wide"
                />
            </div>
            <div className="form-group">
                <div>
                    <input
                        type="text"
                        value={expiry}
                        onChange={handleExpiryChange}
                        required
                        placeholder="MM/YY"
                        maxLength={5} // MM/YY format
                    />
                </div>
                <div>
                    <input
                        type="text"
                        value={cvc}
                        onChange={handleCVCChange}
                        required
                        placeholder="CVV"
                        maxLength={3}
                    />
                </div>
            </div>
            <button type="submit" className={!isFormValid ? 'disabled-button' : ''}>Pay Now</button>
            <button type="button" className="cancel-button" onClick={() => window.history.back()}>
                Cancel
            </button>
        </form>
    );
};

export default CardDetailsForm;
