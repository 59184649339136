import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);

    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then((registration) => {
            console.log('ServiceWorker registered:', registration);



            const handleServiceWorkerMessage = (event: MessageEvent) => {
                console.log('Received message from service worker:', event);

                if (event.data && event.data.type === 'NAVIGATE' && event.data.url) {
                    console.log('Navigating to:', event.data.url);
                    window.location.href = event.data.url; // Perform the navigation
                }
            };

            // Add the service worker message listener
            navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

            // Re-add message listener if page is restored from bfcache
            window.addEventListener('pageshow', (event) => {
                if (event.persisted) {
                    console.log("Page restored from bfcache, re-adding service worker listener.");
                    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
                }
            });
        }).catch((error) => {
            console.error('ServiceWorker registration failed:', error);
        });
    });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
