import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

const SuccessPage: React.FC = () => {
    const [countdown, setCountdown] = useState(30);
    const navigate = useNavigate();
    const linkRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        if (countdown === 0) {
            navigate('/');
        }

        return () => clearInterval(timer);
    }, [countdown, navigate]);

    const openReceiptPage = () => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    };

    return (
        <div className="success-container">
            <div className="success-icon">✔️</div>
            <h1>Payment Successful!</h1>
            <p>Your payment was successfully processed. You will be redirected to the original page in {countdown} seconds. Thank you for your purchase, and we hope you enjoy our service!</p>

            <button className="download-receipt-button" onClick={openReceiptPage}>
                If you want to download and check your receipt, click HERE
            </button>

            <p className="powered-by">Powered by ZirooPay</p>
            <Link
                to="/receipt"
                target="_blank"
                ref={linkRef}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default SuccessPage;
