import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import axios from 'axios';
import CardDetailsForm from '../components/CardDetailsForm';
import LoadingSpinner from '../components/LoadingSpinner';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faPhone, faUniversity} from "@fortawesome/free-solid-svg-icons";
import {formatAmount} from "../components/Receipt";
import {getFromLocalStorage, setToLocalStorage} from "../localStorageUtils";
import LoadingSpinnerButton from "../components/LoadingSpinnerButton";
import {Tooltip} from "@mui/material";

const CheckoutPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
    const [paymentMethod, setPaymentMethod] = useState<'card' | 'phone' | 'transfer'>('card');
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const [tempAccountDetails, setTempAccountDetails] = useState<{
        accountNumber: string;
        accountName: string;
    } | null>(null);
    const [isCountdownActive, setIsCountdownActive] = useState(false);
    const [timeLeft, setTimeLeft] = useState(1800);
    const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isProcessingPhonePayment, setIsProcessingPhonePayment] = useState(false);
    const [isCheckingPayment, setIsCheckingPayment] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);


    const queryParams = new URLSearchParams(location.search);
    const trxId = queryParams.get('trxId');
    const status = queryParams.get('status');
    const approved = queryParams.get('approved');

    // Retrieve price and currencyCodeNumber from location.state
    const price = getFromLocalStorage('amount') as string;
    const currencyCodeNumber = getFromLocalStorage('currencyCodeNumber');
    setToLocalStorage('paymentMethod', paymentMethod);
    setToLocalStorage('currency', currencyCodeNumber === '566' ? '₦' : '$');
    setToLocalStorage('price', price);
    setToLocalStorage('receiptNumber', id);
    setToLocalStorage('accountNumber', tempAccountDetails?.accountNumber);
    setToLocalStorage('bankName', tempAccountDetails?.accountName);
    setToLocalStorage('phoneNumber', phoneNumber);

    const validatePhoneNumber = (number: string) => {
        const phoneRegex = /^[0-9]{10,15}$/;
        return phoneRegex.test(number);
    };

    const fetchPaymentSummary = async () => {
        setIsSummaryLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/payment/summary/${id}`);
            const { amount, currencyCodeNumber, status } = response.data;

            if (status === 'EXPIRED') {
                navigate('/failure', {
                    state: { id, message: `Purchase ID ${id} has expired. Retry is not allowed.` },
                });
                return;
            }

            if (status === 'APPROVED') {
                navigate('/failure', {
                    state: { id, message: `Purchase ID ${id} has already been completed. Retry is not allowed.` },
                });
                return;
            }

            // Store amount and currency code number for ongoing transactions
            setToLocalStorage('amount', amount);
            setToLocalStorage('currencyCodeNumber', currencyCodeNumber);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching payment summary:', error);
            navigate('/failure', { state: { id, message: 'Failed to fetch payment summary. Please try again.' } });
        } finally {
            setIsSummaryLoading(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!trxId) {
            fetchPaymentSummary();
        } else {
            setIsLoading(false);
        }
    }, [id]);

    const handlePayByPhone = async () => {
        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneError('Invalid phone number. Please enter a valid number.');
            return;
        }
        setPhoneError('');
        setIsProcessingPhonePayment(true);

        const data = JSON.stringify({
            returnUrl: `${process.env.REACT_APP_BASE_URL}/checkout/${id}`,
            mobile: phoneNumber,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/api/payment/create/payattitude/${id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'ACCESS-TOKEN': 'asdfasdf',
            },
            data: data,
        };

        try {
            const response = await axios(config);

            // Check the status in the response
            if (response.data.Status === 'APPROVED') {
                setToLocalStorage('date', new Date().toISOString());
                navigate('/success');
            } else {
                navigate('/failure', {state: {id}});
            }
        } catch (error) {
            console.error('Error during payment:', error);
            navigate('/failure', {state: {id}});
        } finally {
            setIsProcessingPhonePayment(false);
        }
    };


    useEffect(() => {
    }, [id, location]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('trxId')) {
            setIsLoading(false);
        }
    }, [location.search]);

    const checkPaymentStatus = async () => {
        setIsLoading(true);
        setIsCheckingPayment(true);
        try {
            // Commenting out the real backend call for now
            // const response = await axios.get(`/api/check-payment-status/${tempAccountDetails?.id}`);
            // if (response.status === 200 && response.data.status === 'SUCCESS') {
            //     navigate('/success', { state: { paymentMethod, currency, price, date: new Date().toISOString(), receiptNumber: id } });
            // } else {
            //     navigate('/failure');
            // }
            // Mock response for testing
            /*const mockStatus = 'SUCCESS';
            if (mockStatus === 'SUCCESS') {
                setToLocalStorage('date', new Date().toISOString());
                navigate('/success');
            } else {
                navigate('/failure');
            }*/
        } catch (error) {
            console.error('Error:', error);
            navigate('/failure');
        } finally {
            setIsLoading(false);
        }
    };

    const createTemporaryAccount = async () => {
        setIsLoading(true);
        try {
            // Commenting out the real backend call for now
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/payment/createPayByTransfer/${id}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log(response)
            if (response.status === 200) {
                const timeBasedAccount = response.data?.timeBasedAccount?.data || {};
                const permanentAccount = response.data?.permanentAccount || {};

                const accountNumber = timeBasedAccount.accountNumber || permanentAccount.accountNumber || 'N/A';
                const accountName = timeBasedAccount.accountName || permanentAccount.accountName || '/';

                setTempAccountDetails({accountNumber, accountName});
                setIsCountdownActive(true);

                const ws = new WebSocket(
                    `${process.env.REACT_APP_API_BASE_URL!.replace(/^http/, 'ws')}/api/payment/socket/${id}`
                );
                console.log(ws.url)
                setWebSocket(ws);

                ws.onopen = () => {
                    console.log("WebSocket connection established");
                };

                ws.onmessage = (event) => {
                    console.log("WebSocket message received:", event.data);

                    // Parse the message and check if it indicates a successful payment
                    setIsCheckingPayment(false);
                    const message = JSON.parse(event.data);
                    if (message.webPurchaseId) {
                        setToLocalStorage('beneficiaryAccountNumber', message.beneficiaryAccountNumber || '/');
                        setToLocalStorage('beneficiaryAccountName', message.beneficiaryAccountName || '/');
                        setToLocalStorage('amount', message.amount || '/');
                        setToLocalStorage('transactionDate', message.transactionDate || '/');
                        setToLocalStorage('originatingBankName', message.originatingBankName || '/');
                        setToLocalStorage('originatingAccountName', message.originatingAccountName || '/');
                        setToLocalStorage('originatingAccountNumber', message.originatingAccountNumber || '/');
                        setToLocalStorage('sessionId', message.sessionId || '/');
                        setToLocalStorage('status', message.status || '/');
                        setToLocalStorage('referenceID', message.referenceID || '/');
                        navigate('/success');
                    }
                };

                ws.onclose = () => {
                    console.log("WebSocket connection closed");
                };

                ws.onerror = (error) => {
                    console.error("WebSocket error:", error);
                };
            } else {
                console.error('Failed to create temporary account');
                navigate('/failure');
            }
        } catch (error) {
            console.error('Error:', error);
            navigate('/failure');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (paymentMethod === 'transfer') {
            createTemporaryAccount();
        }
        return () => {
            // Cleanup WebSocket connection
            if (webSocket) {
                webSocket.close();
            }
        };
    }, [paymentMethod]);

    useEffect(() => {
        if (isCountdownActive && timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsCountdownActive(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isCountdownActive]);

    const renderPaymentContent = () => {
        switch (paymentMethod) {
            case 'phone':
                return (
                    <div className="pay-by-phone-container">
                        <h2>Pay by Phone</h2>
                        <p>Please enter your phone number to proceed with the payment.</p>
                        <input
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter phone number"
                            className="phone-input"
                            disabled={isProcessingPhonePayment}
                        />
                        {phoneError && <p className="error-message">{phoneError}</p>}
                        <button
                            onClick={handlePayByPhone}
                            className="pay-now-button"
                            disabled={isProcessingPhonePayment}
                        >
                            {isProcessingPhonePayment ? 'Processing...' : 'Pay Now'}
                        </button>
                    </div>
                );
            case 'transfer':
                return (
                    <div className="temp-account-details">
                        {tempAccountDetails ? (
                            <div>
                                <div className="transfer-details-box">
                                    <h2 className="title">Transfer Details</h2>
                                    <p>Account Number: {tempAccountDetails.accountNumber}</p>
                                    <p>Account Name: {tempAccountDetails.accountName}</p>
                                </div>
                                <p className={`time-left ${timeLeft < 120 ? 'critical' : ''}`}>
                                    Time Left: {Math.floor(timeLeft / 60)}:{('0' + (timeLeft % 60)).slice(-2)}
                                </p>
                                <button onClick={checkPaymentStatus} disabled={isCheckingPayment || isLoading}
                                        className="payment-done-button">{isCheckingPayment ? (
                                    <span className="spinners-container">
                                <LoadingSpinnerButton/>
                            </span>
                                ) : (
                                    'Payment Done'
                                )}</button>
                            </div>
                        ) : (
                            <LoadingSpinner/>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleCheckPaymentStatus = async () => {
            if (trxId && status && approved !== null) {
                console.log(trxId);
                if (status === 'APPROVED' && approved === 'True') {
                    const config = {
                        method: 'get',
                        url: `${process.env.REACT_APP_API_BASE_URL}/api/payment/card/${trxId}`,
                        headers: {
                            'ACCESS-TOKEN': 'asdfasdf'
                        }
                    };

                    try {
                        const response = await axios(config);
                        if (response.data.status === 'APPROVED') {
                            setToLocalStorage('date', new Date().toISOString());
                            navigate('/success');
                        } else {
                            navigate('/failure', {state: {id}});
                        }
                    } catch (error) {
                        console.error('Error fetching payment status:', error);
                        navigate('/failure', {state: {id}});
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    navigate('/failure', {state: {id}});
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        handleCheckPaymentStatus();
    }, [status, approved, trxId, navigate, id, location]);

    const handleSubmit = async (cardDetails: { cardNumber: string; expiry: string; cvc: string }) => {
        setIsLoading(true);
        setToLocalStorage('cardNumber', cardDetails.cardNumber.replace(/\s+/g, ''));
        setToLocalStorage('expiry', cardDetails.expiry);

        const data = JSON.stringify({
            cardNumber: cardDetails.cardNumber.replace(/\s+/g, ''),
            expiry: cardDetails.expiry,
            cvv: cardDetails.cvc,
            returnUrl: `${process.env.REACT_APP_BASE_URL}/checkout/${id}`
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/api/payment/create/${id}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'ACCESS-TOKEN': 'asdfasdf'
            },
            data: data
        };

        try {
            const response = await axios(config);
            console.log(response)
            setRedirectUrl(response.data.completeUrl);

        } catch (error) {
            console.error('Error initiating payment:', error);
            navigate('/failure', {state: {id}});
        }
    };


    return (
        <div className="checkout-page-container">
            <div className="sidebar">
                <p className="sidebar-title">PAYMENT METHOD</p>
                <button onClick={() => setPaymentMethod('card')} className={paymentMethod === 'card' ? 'active' : ''}>
                    <FontAwesomeIcon icon={faCreditCard}/> Card Payment
                </button>
                <hr/>
                <Tooltip
                    title={currencyCodeNumber !== '566' ? 'This payment type is unavailable for foreign transactions' : ''}>
                <span>
                <button onClick={() => setPaymentMethod('phone')} className={paymentMethod === 'phone' ? 'active' : ''}
                        disabled={currencyCodeNumber !== '566'}>
                    <FontAwesomeIcon icon={faPhone}/> Pay by Phone
                </button>
                     </span>
                </Tooltip>
                <hr/>
                <Tooltip
                    title={currencyCodeNumber !== '566' ? 'This payment type is unavailable for foreign transactions' : ''}>
                <span>
                <button onClick={() => setPaymentMethod('transfer')}
                        className={paymentMethod === 'transfer' ? 'active' : ''}
                        disabled={currencyCodeNumber !== '566'}>
                    <FontAwesomeIcon icon={faUniversity}/> Pay by Transfer
                </button>
                    </span>
                </Tooltip>
            </div>
            <div className="checkout-content">
                <div className="headerCheckout">
                    <div className="logo-container">
                        <div className="logo-background">
                            <img src="/logo.png" alt="Logo" className="logo-image"/>
                        </div>
                    </div>
                    <div className="payment-summary">
                        <p>Amount: {currencyCodeNumber === '566' ? '₦' : '$'}{formatAmount(price)}</p>
                    </div>
                </div>
                {paymentMethod === 'card' && (
                    <div className="card-payment-container">
                        <div className="credit-card-preview" style={{margin: '0 auto'}}>
                            <div className="bank">BANK</div>
                            <div className="chip"></div>
                            <div className="card-number" style={{whiteSpace: 'nowrap'}}>1234 5678 9123 4567</div>
                            <div className="expiry-cvv">
                                <div className="expiry-date">Expiry: 12/24</div>
                                <div className="cvv">CVV: 123</div>
                            </div>
                            <div className="cardholder-name">CARDHOLDER NAME</div>
                        </div>
                        <CardDetailsForm onSubmit={handleSubmit}/>
                    </div>
                )}

                {!isLoading && !trxId && !status && !approved && renderPaymentContent()}

                <p className="powered-by">Powered by ZirooPay</p>

                {redirectUrl && (
                    <iframe
                        title="hidden iFrame"
                        src={redirectUrl}
                    />
                )}

                {isLoading && (
                    <div className="loading-overlay">
                        <LoadingSpinner/>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CheckoutPage;
